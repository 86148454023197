












import {Component, Prop, Vue} from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import Form from '@/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import User from '@/shared/modules/user/user'
import {AuthGetters} from '@/shared/store/auth/auth.getters'
import Client from '@/shared/modules/client/client'
import HttpMethod from '@/shared/configs/http-method.config'
import Field, {FieldSize} from '@/shared/classes/form/field'
import {GlobalActions} from '@/shared/store/global/global.actions'
import can from '@/shared/helpers/can'
import {Permission} from '@/shared/configs/permission.config'
import {FieldType} from '@/shared/configs/field-type.config'
import ArrayField from '@/shared/classes/form/fields/array-field'

@Component({
  components: { Form, PageHeader },
  methods: { can },
})
export default class EditCalculatorDialog extends Vue {
  @Prop() meta!: any
  Permission = Permission

  form: FormBase = new FormBase()
    .setUuid(this.meta.calculator.uuid)
    .setMethod(HttpMethod.PUT)
    .setEndpoint(`clients/${ this.selectedClient.uuid }/calculators`)
    .setFields([
      new Field()
        .setKey('name')
        .setTitle('Name')
        .setSize(FieldSize.half),
      // new Field()
      //   .setType(FieldType.comboBox)
      //   .setTitle('Whitelisted IPs')
      //   .setKey('whitelisted_ips')
      new Field()
          .setType(FieldType.checkbox)
          .setKey('use_aliases')
          .setTitle('Use Aliases ?')
          .setSize(FieldSize.half)
      ,
      new Field()
        .setType(FieldType.textArea)
        .setTitle('Description')
        .setKey('description'),
      new ArrayField()
        .setKey('whitelisted_ips')
        .setChildren([
          new Field()
            .setKey('ip')
            .setTitle('IP address')
            .setSize(FieldSize.fourTwelfth)
            .setClasses('mr-3'),
          new Field()
            .setKey('note')
            .setTitle('Note')
            .setSize(FieldSize.eightTwelfth),
        ])
        .setFirstDelete(true)
    ])
    .setInitialValues({
      whitelisted_ips: [
        {
          ip: '',
          note: '',
        }
      ]
    })
    .setSubmit(false)

  closeDialog() {
    this.$store.dispatch(GlobalActions.closeDialog)
  }

  async submitForm() {
    const { form }: any = this.$refs

    const errors = await form.submit()
    if (errors) return

    this.closeDialog()
    this.meta.onSuccess && this.meta.onSuccess()
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get selectedClient(): Client {
    return this.user.selected_client || this.user.clients[0]
  }
}
