











































import { Component, Provide, Vue } from 'vue-property-decorator'
import CalculatorItem from '@/components/calculators/CalculatorItem.vue'
import Tabs from '@/components/tabs/Tabs.vue'
import Tab from '@/shared/classes/tabs/tab'
import { Routes } from '@/shared/router'
import http from '@/shared/http'
import User from '@/shared/modules/user/user'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import ResponseInterface from '@/shared/interfaces/response.interface'
import Calculator from '@/shared/modules/calculator/calculator'
import { GlobalActions } from '@/shared/store/global/global.actions'
import Client from '@/shared/modules/client/client'
import EditCalculatorDialog from '@/components/calculators/EditCalculatorDialog.vue'
import { CalculatorStatus } from '@/shared/configs/calculator-statuses'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'

@Component({
  components: { Tabs, CalculatorItem }
})
export default class CalculatorInnerLayout extends Vue {
  @Provide('calculator') getCalculator(): Calculator | null {
    return this.calculator
  }
  @Provide('refreshCalculator') refreshCalculator(): void {
    this.loadCalculator()
  }
  CalculatorStatus = CalculatorStatus
  calculatorUrl: string = ''
  showUrlTooltip: boolean = false

  tabs: Tab[] = [
    new Tab()
      .setName(Routes.calculatorParameters)
      .setTitle('Parameters')
      .setIcon(require('@/assets/images/calculators/parameters.svg')),
    new Tab()
      .setName(Routes.calculatorDataTables)
      .setTitle('Data tables')
      .setIcon(require('@/assets/images/calculators/datatables.svg')),
    new Tab()
      .setName(Routes.calculatorRules)
      .setTitle('Rules')
      .setIcon(require('@/assets/images/calculators/rules.svg')),
    new Tab()
      .setName(Routes.calculatorCalculationSteps)
      .setTitle('Calculation steps')
      .setIcon(require('@/assets/images/calculators/calculation-steps.svg')),
    new Tab()
      .setName(Routes.calculatorVersionHistory)
      .setTitle('Version history')
      .setIcon(require('@/assets/images/calculators/history.svg')),
    new Tab()
      .setName(Routes.calculatorTestSets)
      .setTitle('Test sets')
      .setIcon(require('@/assets/images/calculators/test-set.svg')),
    new Tab()
      .setName(Routes.calculatorLogs)
      .setTitle('Calculation Logs')
      .setIcon(require('@/assets/images/calculators/test-set.svg')),
  ]
  calculator: Calculator | null = null

  async created() {
    await this.loadCalculator()

    this.calculatorUrl = `${ process.env.VUE_APP_API_URL }/clients/${ this.selectedClient.uuid }/calculators/${ this.getCalculator()?.reference }/calculate`
  }

  async loadCalculator() {
    await http.get(`clients/${ this.selectedClient.uuid }/calculators/${ this.$route.params.uuid }`)
      .then((response: ResponseInterface<Calculator>) => {
        this.calculator = response.data
      })
      .catch(() => this.$router.replace({ name: Routes.calculators }))
  }

  openEditDialog() {
    this.$store.dispatch(GlobalActions.showDialog, {
      component: EditCalculatorDialog,
      maxWidth: 1100,
      meta: {
        calculator: this.calculator,
        onSuccess: () => this.loadCalculator()
      }
    })
  }

  publishVersion() {
    this.$store.dispatch(GlobalActions.showDialog, {
      component: AreYouSureDialog,
      maxWidth: 400,
      meta: {
        onYes: () => {
          http.post(`clients/${ this.selectedClient.uuid }/calculators/${ this.$route.params.uuid }/publish`)
            .then((response: any) => window.location.reload())
        }
      }
    })
  }

  copyUrl() {
    this.$copyText(this.calculatorUrl)
    this.showUrlTooltip = true

    setTimeout(() => this.showUrlTooltip = false, 2000)
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get selectedClient(): Client {
    return this.user.selected_client || this.user.clients[0]
  }
}
